@mixin hideScrollBars {
    overflow: scroll;

    /* Webkit browsers */
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
    }

    /* Firefox */
    scrollbar-width: none;

    /* IE and older Edge */
    -ms-overflow-style: none;
}

$scroll-width: 1rem;
@mixin showVerticalScrollBarsOnHover {
    position: relative; /* Ensure the element's position is relative */
    margin-right: -$scroll-width; /* Negative margin to offset the scrollbar width on hover */
    padding-right: $scroll-width;

    &:hover {
        padding-right: 0;

        /* Webkit browsers */
        &::-webkit-scrollbar {
            width: $scroll-width; /* or any desired width */
            display: block;
        }
        scrollbar-width: auto; /* shows a thin scrollbar on hover */
        &::-webkit-scrollbar:horizontal {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--text-color);
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }
}
