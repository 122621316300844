@import '../../../styles/delayed';
@import '../../../styles/clickableText.module';
@import '../../../styles/centerChildrenVertically.module';

.twoPartPillButtonContainer {
    display: flex;

    .pill {
        @include center-children-vertically;
        @include delayed;

        padding: 6px 15px 6px 15px;
        background-color: var(--highlight);
        border: none;
        @include clickMe;
        color: var(--text-color);

        svg {
            vertical-align: middle;
        }

        a {
            color: var(--text-color);
        }

        &.isSelected {
            background-color: var(--color-five);
            color: var(--black);
        }

        &:hover {
            background-color: var(--color-five);
            color: var(--black) !important;

            a {
                color: var(--black);
            }

            svg {
                color: var(--text-color);
                stroke: var(--text-color);
            }
        }

        &:disabled {
            background-color: var(--highlight);
            color: var(--black);
            cursor: not-allowed;
        }
    }

    .leftPill {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .rightPill {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }
}
