@import '../../../../styles/delayed';

.contentDisplay {
    color: var(--text-color);
    background-color: var(--highlight);
    padding: 0.5rem;
    border-radius: 8px;
    margin-top: 0.5rem;
    user-select: none;

    @include delayed;

    &:hover {
        background-color: var(--color-five);
        color: var(--black);
        border: none;
    }

    &.disabled {
        opacity: 80%;

        &:hover {
            background-color: var(--highlight);
            color: var(--text-color);
            border: none;
        }
    }
}
