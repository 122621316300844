@import '../../../styles/responsiveText';
@import '../../../styles/delayed';
@import '../../../styles/centerChildrenVertically.module';

.logoLoggedOut {
    .siteTitleContainer {
        width: 100%;
        @include delayed;
        height: $logged-in-header-height;
        text-decoration: none;
        @include center-children-vertically;

        &:hover {
            color: var(--color-five) !important;
            cursor: pointer;

            .siteTitle {
                color: var(--color-five) !important;
            }
        }

        .siteTitle {
            font-size: 24px;
            padding: 0.5rem 1rem;
            letter-spacing: 0.3rem;
            display: flex;
            justify-content: center;
            border-radius: 8px;
            @include delayed;

            .logoContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;

                // mimics antd styles that are applied a fraction too late on first page load
                :global {
                    .anticon {
                        line-height: 0;
                    }
                }
            }

            color: whitesmoke;
            opacity: 0.9;

            &:hover {
                opacity: 1;
            }
        }
    }
}
