@import '../../../styles/media';
@import '../../../styles/clickableText.module';
@import '../../../styles/centerChildrenVertically.module';

.searchLink {
    display: flex;

    .searchLinkBackgroundColor {
        background-color: var(--black);
        color: var(--text-color);
        border-radius: 8px;
        min-width: 10rem;
        max-width: 50rem;
        flex: 75%;
        height: 35px;

        .inputStyles {
            height: 100%;
            color: var(--text-color);
            padding-left: 1rem;
            font-size: 14px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .searchGlassContainer {
        flex: 25%;

        .searchGlass {
            @include center-children-vertically;
            background-color: inherit;
            color: var(--text-color);
            border: none;
            height: 100%;

            &:hover {
                color: var(--color-five) !important;
            }

            &:focus-visible {
                border: none;
            }
        }
    }
}
