.contactInstructorButtonContainer {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;

    .contactInstructorButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        :global {
            .ant-btn {
                width: 100%;
            }
        }
    }
}
