@import '../../../styles/variables';

.loadingContainerContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    //max-width: 500px;
    padding: 3rem;
    @media (max-width: $small-screen-max-width) {
        padding: 1rem;
    }
    width: 100%;
    //min-width: 320px;
    margin: 0 auto;
    background: transparent;

    .loadingContainer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        img {
            width: 100%;
        }
    }
}

.loadingContainerCoverEntireScreen {
    position: fixed;
    padding: 0;
    margin: 0;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background: var(--background);
}
