@import '../../styles/variables';
@import '../../styles/media';

@mixin wide-mode {
    grid-template-areas:
            "title icon"
            "text icon"
            "buttons buttons";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
}

@mixin tall-mode {
    grid-template-areas:
                "icon"
                "title"
                "text"
                "buttons";
    grid-template-columns: auto;
    grid-template-rows: auto 1fr 2fr auto;
}

.actionCardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: 650px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }
}

.actionCard {
    display: grid;
    width: 100%;
    @include wide-mode;

    gap: 1rem;
    padding: 1rem;
    border-radius: $border-radius;
    color: var(--white);
    min-width: 0;

    &.primary {
        background-color: var(--color-one);
        color: var(--white);
    }

    &.secondary {
        background-color: var(--color-five);
        color: var(--white);
    }

    &.noAction {
        background-color: var(--black);
        color: var(--white);
    }

    .titleContainer {
        grid-area: title;

        .bigBoldText {
            font-weight: $default-bold-weight;
            font-size: 150%;
        }
    }

    .textContainer {
        grid-area: text;
    }

    .iconContainer {
        grid-area: icon;

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        img {
            max-width: 100px;
            height: auto;
            margin: 0 auto;
        }
    }

    .buttonContainer {
        grid-area: buttons;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;

        a, button, div {
            width: 100%;
        }
    }
}
