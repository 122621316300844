@import '../../../../styles/delayed';
@import '../../../../styles/variables';
@import '../../../../styles/limitMaxWidth.module';
@import '../../../../styles/clickableText.module';

.globalBreadcrumbs {
    @include limit-max-width;
    display: flex;
    padding: 0.25rem 0;

    .globalBreadcrumbComponent {
        .lastComponent {
            color: var(--text-color);
            font-weight: $default-bold-weight;
            @include dontTouchMe;
        }

        a {
            color: var(--text-color);
            text-decoration: none;

            @include delayed;

            &:hover {
                color: var(--color-five);
            }
        }
    }

    .breadcrumbDivider {
        color: var(--text-color);
        padding: 0 0.25rem;
    }
}
