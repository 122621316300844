@import '../../../styles/variables';
@import '../../../styles/limitMaxWidth.module';

.frameContainer {
    max-width: 100vw;
    min-width: 340px; // some margin is required from the standard 360px, idk why
    height: 100%;
    min-height: calc(100vh - $footer-height) !important;
    background-color: var(--background) !important;
    padding-bottom: 1rem;

    .frameContainerLoggedIn {
        display: flex;
        justify-content: center;
        min-height: calc(100vh - $footer-height - 1rem);
    }

    .frameBodyLoggedOut {
        display: flex;
        justify-content: center;

        .frameBodyLoggedOutInner {
            &.isSplashPage {
                width: 100%;
            }

            &.notSplashPage {
                @include limit-max-width;
                margin: 0 1rem;
            }
        }
    }

    .frameBodyLoggedIn {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @include limit-max-width;
        margin: 0 1rem 0 0;
        @media (max-width: $logged-in-mobile-mode-breakpoint) {
            margin: 0 1rem;
        }
    }
}
