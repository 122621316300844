@import '../../../../../styles/responsiveText';
@import '../../../../../styles/clickableText.module';
@import '../../../../../styles/delayed';

.recentlySearchedTopicContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .contentTypeTitleContainer {
        @include responsive-text-small;
        background-color: var(--black);
        padding: 0.5rem;
        border-radius: 8px;
        text-align: center;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        img {
            max-height: 25px;
            width: auto;
            vertical-align: center;
            height: 100%;
        }

        &.isLocked {
            background-color: var(--black-five-pct-lighter);
            cursor: not-allowed;
        }
    }

    .searchOptions {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;

        .contentTypeSearchOption {
            @include responsive-text-small;
            @include clickMe;
            background-color: var(--highlight);
            padding: 0.5rem;
            border-radius: 8px;
            text-align: center;
            @include delayed;

            &:hover {
                background-color: var(--color-five);
                color: var(--black);
                border: none;
            }

            &.isLocked {
                @include dontTouchMe;
                pointer-events: none;
                &:hover {
                    background-color: var(--highlight);
                    color: var(--black);
                }
            }
        }
    }
}
