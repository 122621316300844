@import 'variables.scss';
@import 'antdStyles.module.scss';

div,
span,
input {
    font-family: var(--font-text);
}

.click-me {
    cursor: pointer;
}

.no-highlight {
    user-select: none;
}

.ant-notification {
    .ant-notification-notice {
        background-color: var(--course-card-color);
        color: var(--text-color);

        .ant-notification-notice-content {
            .anticon {
                color: var(--color-five);
            }
            .ant-notification-notice-message {
                color: var(--text-color);
                font-weight: $default-bold-weight;
            }

            .ant-notification-notice-icon-success {
                svg {
                    color: var(--green);
                }
            }

            .ant-notification-notice-icon-error {
                svg {
                    color: var(--red);
                }
            }
        }
    }
    .ant-notification-notice-close {
        .ant-notification-close-x {
            color: var(--text-color);
        }
    }
}

.ant-dropdown {
    .ant-table-filter-dropdown {
        background-color: var(--background);
        .ant-dropdown-menu {
            background-color: var(--background);
            .ant-dropdown-menu-item {
                .ant-dropdown-menu-title-content {
                    .ant-checkbox-wrapper + span {
                        color: var(--text-color);
                    }
                }
            }

            .ant-dropdown-menu-item-selected {
                background-color: var(--color-five);
                .ant-dropdown-menu-title-content {
                    .ant-checkbox-wrapper + span {
                        color: var(--black);
                    }

                    // copied from antdStyles - antdCheckboxStyle
                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: var(--text-color);
                        border: solid 1px var(--color-five);
                    }

                    .ant-checkbox-checked .ant-checkbox-inner:after {
                        border-color: var(--color-one) !important;
                    }

                    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
                        background-color: var(--color-one);
                    }
                    // copied from antdStyles - antdCheckboxStyle
                }
            }
        }

        .ant-table-filter-dropdown-btns {
            .ant-btn-link {
                color: var(--color-three);
            }

            .ant-btn-primary {
                background-color: var(--color-one);
                &:hover {
                    background-color: var(--text-color);
                    color: var(--black);
                }
            }
        }
    }
}

.ant-image {
    .ant-image-mask {
        border-radius: 8px;
    }
}
