@import '../../../../styles/variables';
@import '../../../../styles/media';
@import '../../../../styles/delayed';
@import '../../../../styles/centerChildrenVertically.module';

.pausePlayContainer {
    @include center-children-vertically;
}

.clockMenuItemContainer {
    width: 196px;
    @include respond-to(lg2) {
        width: 86px;
    }

    @media (max-width: $logged-in-mobile-mode-breakpoint) {
        width: 100%;
    }

    .clockMenuItem {
        @include delayed;
        border: 1px solid transparent;

        height: 100%;
        display: flex;
        margin: 0 auto;
        align-items: center;
        overflow: visible;
        padding: 0.5rem 0.75rem;
        border-radius: 8px;
        font-size: 14px;
        cursor: pointer;
        width: 100%;

        @include respond-to(lg2) {
            width: fit-content;
        }

        @include respond-to(sm) {
            width: 100%;
        }

        &.play {
            background-color: var(--color-three);
            color: var(--black) !important;

            &:hover {
                border-color: var(--black) !important;
            }
        }

        &.paused {
            background-color: var(--color-one);
            color: var(--text-color) !important;

            &:hover {
                border-color: var(--color-five) !important;
            }
        }

        &.disabled {
            background-color: var(--highlight);
            cursor: not-allowed;
            color: var(--text-color);
            opacity: 50%;

            &:hover {
                background-color: var(--highlight);
            }
        }

        .buttonContentsContainer {
            display: flex;
            width: 100%;

            .timeText {
                @include center-children-vertically;
                padding-right: 0.75rem;
                border-right: solid 1px var(--black);
                margin-right: 0.5rem;
                font-weight: $smaller-bold-weight;
            }

            .textContainer {
                @include center-children-vertically;
                text-align: center;
                width: 100%;
            }

            .pausePlayContainer {
                margin-left: 0.25rem;
                margin-right: 0.25rem;

                svg {
                    g {
                        stroke: var(--black);
                    }
                }
            }

            :global {
                .anticon-clock-circle {
                    &:hover {
                        color: var(--color-five);
                    }
                }

                .anticon > svg {
                    overflow: visible;
                }
            }

            .timeIcons {
                font-size: 100% !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: var(--color-two);

                &.pausePlayIcon {
                    margin-left: 0.25rem;
                }

                &.timeIconsContrasted {
                    color: var(--text-color);

                    &:hover {
                        opacity: 0.8;
                    }
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }
    }
}
