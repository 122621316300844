$animate-on-hover-duration: 0.5s;

.animateOnHoverContainer:hover {
    .animateOnHover {
        transform: translateX(25%) scale(110%);
    }
}

.animateOnHover {
    display: inline-block;
    transition: transform 0.5s;

    // mimics antd styles that are applied a fraction too late on first page load
    :global {
        .anticon {
            vertical-align: -0.125em;
        }
    }
}

.animateOnHoverContainer {
    transition: transform 0.5s;

    &:hover {
        transform: scaleY(95%);
    }

    :global {
        .anticon {
            margin-inline-start: 0 !important;
        }
    }
}
