@import '../../../../styles/responsiveText';
@import '../../../../styles/variables';
.askEupheusSearchMenu {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.askEupheusSearchMenu {
    color: var(--text-color);
    .queryResults {
        .queryResultsHeader {
            display: flex;
            justify-content: space-between;
            column-gap: 2rem;
            .queryText {
                color: red;
                font-style: italic;
            }
        }
        .queryResultsBody {
            .responseText {
                @include responsive-text-extra-small;
                background-color: var(--highlight);
                padding: 0.5rem;
                border-radius: 8px;
                color: var(--text-color);
                text-align: left;
            }

            .relevantContentSectionHeader {
                @include responsive-text-small;
                background-color: var(--black);
                padding: 0.5rem;
                border-radius: 8px;
                color: var(--text-color);
                font-weight: 600;
                text-align: left;
                margin-top: 1rem;
            }
            .relevantContentSectionsContainer {
                padding-top: 1rem;
                display: flex;
                flex-direction: column;
                text-align: left;
                row-gap: 1rem;
                .relevantContentSectionsBody {
                    @include responsive-text-extra-small;
                    background-color: var(--highlight);
                    padding: 1rem;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 1rem;

                    a {
                        color: var(--text-color);

                        &:hover {
                            color: var(--color-five);
                        }
                    }
                }
            }

            .feedbackContainer {
                margin-top: 2rem;
                @include responsive-text-extra-small;
                display: flex;
                justify-content: center;
            }
        }
        .footer {
            padding-top: 1rem;
        }
    }
}
