@import '../../../../styles/media';
@import '../../../../styles/variables';

.navigationLoggedIn {
    background-color: var(--background);
    padding: 0 2rem;
    width: fit-content;
    margin-top: 1rem;
    @include respond-to(lg2) {
        width: $logged-in-small-desktop-mode-nav-bar-width;
    }

    @include respond-to(xl) {
        border-right: none;
    }

    .logo {
        height: 75px;
        margin: 1rem;
    }
}
