@import '../styles/variables';
@import '../styles/delayed';

.clickableText {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-three);
    @include delayed;
    //@include delayedHoverUnderline;
    width: fit-content;

    &:hover {
        color: var(--color-three);
        font-weight: $smaller-bold-weight;
    }

    a {
        cursor: pointer;
        color: var(--color-three);
    }
}

.underline {
    cursor: pointer;
    color: var(--color-three);
    width: fit-content;
    text-decoration: underline;
    @include delayed;

    &:hover {
        color: var(--color-three);
        font-weight: $smaller-bold-weight;
        text-decoration: underline;
    }

    a {
        cursor: pointer;
        color: var(--color-three);
    }
}

.clickMe {
    cursor: pointer;
}

.dontTouchMe {
    pointer-events: none;
    user-select: none;
}

.cursorNotAllowed {
    cursor: not-allowed;
}

@mixin clickMe {
    cursor: pointer;
}

@mixin dontTouchMe {
    pointer-events: none;
    user-select: none;
}

@mixin cursorNotAllowed {
    cursor: not-allowed;
}

@mixin aTag {
    a {
        text-decoration: none;
    }
}

.boldMe {
    font-weight: $default-bold-weight;
}
