@import '../../styles/media';
@import '../../styles/clickableText.module';
@import '../../styles/centerChildrenVertically.module';

.navigationLoggedInOptionContainer {
    text-decoration: none;

    .navigationLoggedInOption {
        height: fit-content;
        display: flex;
        padding: 0.5rem;
        border-radius: 8px;
        white-space: nowrap;
        column-gap: 1.5rem;
        background-color: var(--background);
        width: 100%;
        border: none;

        @include respond-to(lg2) {
            flex-direction: column;
            width: 86px;
            justify-content: center;
        }

        @include respond-to(sm) {
            flex-direction: row;
            width: 100%;
            column-gap: 0;
            justify-content: normal;
        }

        &.isSelected {
            background-color: var(--black) !important;
        }

        &:hover {
            background-color: var(--color-five);

            .navigationLoggedInText {
                @include center-children-vertically;
                color: var(--text-color);
            }
        }

        .navigationLoggedInIcon {
            @include center-children-vertically;
            width: 50px;
            height: 50px;
            background-color: inherit;
            border-radius: 12px;

            @include respond-to(lg2) {
                margin: 0 auto;
            }

            @include respond-to(sm) {
                margin: 0;
            }

            img {
                max-width: 50px;
                height: auto;
                margin: 0 auto;
            }
        }

        .navigationLoggedInText {
            color: var(--text-color);
            font-size: 16px;
            margin-left: 1rem;
            align-self: center;

            @include respond-to(lg2) {
                margin-left: 0;
                flex-wrap: wrap;
                font-size: 14px;
            }

            @include respond-to(sm) {
                margin-left: 1rem;
            }
        }
    }
}
