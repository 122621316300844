@import '../../../../../styles/delayed';

.sharedCTAButtonStyles {
    @include delayed;
}

.primaryDarkBackground {
    background-color: var(--color-one) !important;
    border-color: var(--color-one) !important;
    color: var(--menu-text-color) !important;

    &:hover {
        background-color: var(--menu-text-color) !important;
        border-color: var(--black) !important;
        color: var(--black) !important;
    }

    &:disabled {
        opacity: 50%;
        background-color: var(--highlight);
        border-color: var(--highlight);
        color: var(--text-color);

        &:hover {
            background-color: var(--color-one) !important;
            border-color: var(--color-one) !important;
            color: var(--menu-text-color) !important;
        }
    }
}

.greenNoBackground {
    background-color: var(--color-three) !important;
    border-color: var(--color-three) !important;
    color: var(--header-text-color) !important;

    &:hover {
        background-color: var(--black) !important;
        border-color: var(--color-three) !important;
        color: var(--menu-text-color) !important;
    }

    &:disabled {
        background-color: var(--highlight);
        border-color: var(--highlight);
        color: var(--text-color);

        &:hover {
            background-color: var(--highlight) !important;
            border-color: var(--highlight) !important;
            color: var(--text-color) !important;
        }
    }
}

.defaultNoBorderDarkBackground {
    background-color: var(--background);
    border-color: var(--background);
    color: var(--color-three);

    &:hover {
        background-color: var(--background) !important;
        border-color: var(--background) !important;
        color: var(--text-color) !important;
    }

    &:disabled {
        background-color: var(--highlight);
        border-color: var(--highlight);
        color: var(--text-color);

        &:hover {
            background-color: var(--highlight) !important;
            border-color: var(--highlight) !important;
            color: var(--text-color) !important;
        }
    }
}

.defaultBorderDarkBackground {
    background-color: var(--background) !important;
    border-color: var(--text-color) !important;
    color: var(--text-color) !important;

    &:hover {
        background-color: var(--background) !important;
        border-color: var(--color-three) !important;
        color: var(--color-three) !important;
    }

    &:disabled {
        background-color: var(--highlight);
        border-color: var(--highlight);
        color: var(--text-color);

        &:hover {
            background-color: var(--highlight) !important;
            border-color: var(--highlight) !important;
            color: var(--text-color) !important;
        }
    }
}

.primaryLightBackground {
    background-color: var(--color-one);
    border-color: var(--color-one);
    color: var(--text-color);

    &:hover {
        background-color: var(--black) !important;
        border-color: var(--black) !important;
        color: var(--text-color) !important;
    }

    &:disabled {
        background-color: var(--highlight);
        border-color: var(--highlight);
        color: var(--text-color);

        &:hover {
            background-color: var(--highlight) !important;
            border-color: var(--highlight) !important;
            color: var(--text-color) !important;
        }
    }
}

.defaultLightBackground {
    background-color: var(--text-color);
    border-color: var(--text-color);
    color: var(--color-three);

    &:hover {
        background-color: var(--text-color) !important;
        border-color: var(--black) !important;
        color: var(--black) !important;
    }

    &:disabled {
        background-color: var(--highlight);
        border-color: var(--highlight);
        color: var(--text-color);

        &:hover {
            background-color: var(--highlight) !important;
            border-color: var(--highlight) !important;
            color: var(--text-color) !important;
        }
    }
}
