@import '../../../styles/variables';
@import '../../../styles/variables';

.headerAnnouncement {
    background-color: var(--color-five);
    color: var(--header-text-color);
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 13px;
    height: 40px;
    align-items: center;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
}
