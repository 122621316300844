@import '../../../../styles/variables';
@import '../../../../styles/responsiveText';
@import '../../../../styles/limitMaxWidth.module';

.errorContainer {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 6rem;
    background: var(--background);
    @media (max-width: $small-screen-max-width) {
        padding: 3rem;
    }

    .error {
        margin: 0 auto;
        @include limit-max-width;

        .header {
            @include responsive-text-large;
            font-weight: $default-bold-weight;
            color: var(--text-color);
            font-style: italic;
            margin-bottom: 2rem;

            display: flex;
            flex-direction: row;
        }

        .text {
            @include responsive-text-small;
            color: var(--text-color);
        }

        a {
            &:hover {
                opacity: 0.75;
            }
        }

        img {
            max-width: 500px;
            width: 100%;
            height: auto;
            margin: 1rem auto;
        }
    }
}
