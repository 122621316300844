@import '../../../styles/variables';
@import '../../../styles/responsiveText';

.buyCoursePromptContainer {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    .buyCoursePromptHeaderText {
        color: var(--text-color);
        @include responsive-text-small;
        font-weight: 700;
        width: fit-content;
    }

    .buyCoursePromptSubText {
        color: var(--text-color);
        @include responsive-text-extra-small;
        width: fit-content;
        margin-top: 0.5rem;
    }
}
