
.genericButton {
    :global {
        .ant-btn {
            .ant-btn-lg {
                padding: 0 15px;
            }
        }
    }
    .grayDarkBackground {
        background-color: var(--highlight);
        border-color: var(--highlight);
        color: var(--text-color);

        &:hover {
            background-color: var(--color-five) !important;
            border-color: var(--color-five) !important;
            color: var(--black) !important;
        }

        &:disabled {
            background-color: var(--highlight);
            border-color: var(--highlight);
            color: var(--text-color);
            opacity: 50%;

            &:hover {
                background-color: var(--highlight) !important;
                border-color: var(--highlight) !important;
                color: var(--text-color) !important;
            }
        }
    }

    .primaryDarkBackground {
        background-color: var(--color-one);
        border-color: var(--color-one);
        color: var(--text-color);

        &:hover {
            background-color: var(--text-color) !important;
            border-color: var(--text-color) !important;
            color: var(--black) !important;
        }

        &:disabled {
            background-color: var(--highlight);
            border-color: var(--highlight);
            color: var(--text-color);
            opacity: 50%;

            &:hover {
                background-color: var(--highlight) !important;
                border-color: var(--highlight) !important;
                color: var(--text-color) !important;
            }
        }
    }

    .greenNoBackground {
        background: transparent;
        border-color: var(--color-three);
        color: var(--color-three);

        &:hover {
            background-color: var(--text-color) !important;
            border-color: var(--text-color) !important;
            color: var(--course-card-color) !important;
        }

        &:disabled {
            background-color: var(--highlight);
            border-color: var(--highlight);
            color: var(--text-color);
            opacity: 50%;

            &:hover {
                background-color: var(--highlight) !important;
                border-color: var(--highlight) !important;
                color: var(--text-color) !important;
            }
        }
    }

    .whiteGhost {
        background: transparent;
        border-color: var(--white);
        color: var(--white);

        &:hover {
            background-color: var(--text-color) !important;
            border-color: var(--text-color) !important;
            color: var(--course-card-color) !important;
        }

        &:disabled {
            background-color: var(--highlight);
            border-color: var(--highlight);
            color: var(--text-color);
            opacity: 50%;

            &:hover {
                background-color: var(--highlight) !important;
                border-color: var(--highlight) !important;
                color: var(--text-color) !important;
            }
        }
    }
}
