@import '../../../styles/variables';
@import '../../../styles/centerChildrenVertically.module';

.feedbackContainer {
    padding-top: 1rem;
    display: flex;
    column-gap: 1rem;

    .text {
        @include center-children-vertically;
        text-align: left;
        font-weight: $smaller-bold-weight;
    }

    .options {
        display: flex;
        column-gap: 1rem;
    }

    :global {
        .anticon {
            font-size: 200%;
            cursor: pointer;
        }
    }
}
