@import '../../../../../styles/responsiveText';
@import '../../../../../styles/antdStyles.module';

.pausedFromInactivityModal {
    @include antdModalStyle;

    .pausedFromInactivityTitle {
        @include responsive-text-extra-small;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .pausedFromInactivityText {
        @include responsive-text-extra-small;
    }

    .pausedFromInactivityModalButton {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        @include responsive-text-small;
    }
}
