@import '../../../../styles/responsiveText';
@import '../../../../styles/media';
@import '../../../../styles/variables';
@import '../../../../styles/hiddenScrollBars.module';

.recentlySearchedTopics {
    display: flex;
    height: 100%;

    @include respond-to(sm) {
        flex-direction: column;
    }
    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 1rem;
        @include responsive-text-small;
        font-weight: $default-bold-weight;
        text-align: center;
        margin: 2rem;
        @include respond-to(sm) {
            margin: 1rem;
        }
        img {
            margin: 0 auto;
            height: auto;
            max-width: 125px;
            @include respond-to(sm) {
                max-width: 60px;
            }
        }
    }

    .recentlySearchedTopicsContainer {
        display: flex;
        justify-content: space-between;
        margin: 0 2rem;
        height: 100%;

        @include respond-to(sm) {
            flex-direction: column;
            row-gap: 0.5rem;
            margin: 0;

            @include hideScrollBars;
        }

        column-gap: 1.5rem;

        div {
            flex: 1;
        }
    }
}
