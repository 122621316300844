@import '../../../styles/media';

.leftSidebarLoggedInSharedContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .logo {
    }

    .genericRoutingButtonsContainer {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        @include respond-to(lg2) {
            a {
                margin: 0 auto;
            }
        }

        @include respond-to(sm) {
            // do not center in mobile mode
            a {
                margin: 0;
            }
        }
    }

    hr {
        width: 100%;
        border: 1px solid var(--white);
    }

    .timerContainer {
    }

    .contactInstructorContainer {
    }
}
