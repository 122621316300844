@import '../../../../styles/responsiveText';
@import '../../../../styles/clickableText.module';

.contentTypeTitle {
    cursor: pointer;
    @include responsive-text-small;
    background-color: var(--black);
    padding: 0.5rem;
    border-radius: 8px;
    margin-bottom: 0.35rem;
    display: flex;
    justify-content: space-between;
    user-select: none;
    @include delayed;

    svg {
        @include delayed;
        color: var(--text-color);
    }

    &:hover {
        color: var(--color-five);

        svg {
            color: var(--color-five);
        }
    }

    &.disabled {
        @include dontTouchMe;
        background-color: var(--black-five-pct-lighter);
        cursor: not-allowed;
    }

    img {
        max-height: 25px;
        width: auto;
    }

    .iconContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .iconContainerInner {
            margin: 0 auto;

            svg {
                display: block;
            }
        }
    }
}
