@import '../../../styles/variables';
@import '../../../styles/antdStyles.module';

.authenticator {
    > div:nth-child(2) {
        width: 100%;
    }

    padding: 1.5rem;
    background-color: var(--course-card-color);
    border-radius: 12px;

    @include antdTabsStyle;

    :global {
        .btn-container {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 1rem;

            button {
                span {
                    font-weight: 700;
                }
            }
        }

        .error-text {
            position: static;
            margin-bottom: 1.5rem;
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 2rem;
        }
    }

    .loginForm {
        @include antdFormStyle;
    }

    .forgotPasswordAndLoginButtonContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 1rem;
        @include antdFormStyle;

        :global {
            .btn-container {
                padding-top: 0;

                &.is-single-column {
                    margin-top: 1rem;
                }
            }
        }
    }

    .forgotPasswordForm {
        width: fit-content;
        margin: 0 auto;

        .forgotPasswordInstructions {
            text-align: left;
            color: var(--text-color);
            margin-bottom: 1rem;
            font-weight: 600;

            span {
                color: var(--black);
            }
        }

        .returnToLoginContainer {
            margin-top: 1rem;
            width: 100%;
        }

        @include antdFormStyle;
    }
}

// remove weird inner box
#password:focus,
#confirmPassword:focus {
    border-color: var(--text-color) !important;
    box-shadow: none !important;
}

// allows spacing between the swipeable views here (may want to extend this to all someday?)
.moreSliderPadding {
    padding: 1rem;
    width: fit-content;
}

#cypress-send-recovery-email-button {
    font-weight: 700;
}

#cypress-login-button {
    font-weight: 700;
}

.googleSignupDivider {
    color: var(--text-color);
    font-weight: 700;
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 1rem;
}

.signUpForm {
    color: var(--text-color);
    @include antdFormStyle;
}

#cypress-sign-up-button {
    font-weight: 700;
}

.authenticatorModal {
    @include antdModalStyle;

    :global {
        .ant-modal-content {
            padding: 0 !important;
        }
    }
}

.loginModal {
    @include antdModalStyle;

    :global {
        .ant-modal-content {
            padding: 1rem !important;
            background-color: var(--black);
            border: solid 2px var(--highlight);
        }
    }
}
