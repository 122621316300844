@import '../../../styles/variables';
@import '../../../styles/media';

.footer {
    background-color: var(--course-card-color);
    //padding: 0.25rem 0;

    .footerContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        width: fit-content;
        background-color: var(--course-card-color);
        color: var(--text-color);
        height: $footer-height;

        .footerContainer {
            display: flex;
            column-gap: 2rem;
            font-size: 12px;
            row-gap: 0.25rem;

            @include respond-to(md) {
                flex-direction: column;
            }
            .copyright {
                text-align: center;
            }

            .linkContainer {
                display: flex;
                column-gap: 0.5rem;
                justify-content: center;
            }

            a {
                text-decoration: none;
            }

            .contactContainer {
                display: flex;
                column-gap: 0.5rem;
            }
        }
    }
}
