@import '../../../../styles/delayed';
@import '../../../../styles/centerChildrenVertically.module';

.mobileModeMenuDrawerContainer {
    @include center-children-vertically;
    cursor: pointer;
    height: 100%;
    color: var(--text-color);
    font-size: 2rem;

    .mobileModeMenuDrawer {
        background-color: var(--highlight);
        padding: 0.5rem;
        border-radius: 8px;
        @include delayed;
    }

    &:hover {
        .mobileModeMenuDrawer {
            background-color: var(--color-five) !important;
        }
    }

    .leftSidebarLoggedInMobileMode {
        .takeMeBack {
            margin-top: 1rem;
        }
    }
}
