@import 'media';
@import 'delayed';
@import 'centerChildrenVertically.module';

@mixin antdTabsStyle {
    :global {
        .ant-tabs-nav {
            margin: 0 !important;
        }

        .ant-tabs-nav-list {
            .ant-tabs-tab {
                border-bottom: 2px solid transparent;
                @include delayed;

                &:hover {
                    border-bottom-color: var(--color-four);
                }

                &.ant-tabs-tab-disabled {
                    .ant-tabs-tab-btn {
                        color: var(--text-color);
                        opacity: 50%;
                    }

                    &:hover {
                        border: 0;
                    }
                }

                .ant-tabs-tab-btn {
                    font-size: 14px;
                    color: var(--gray);
                }

                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: var(--color-four);
                        font-weight: $default-bold-weight;
                    }
                }
            }

            .ant-tabs-ink-bar {
                background: var(--color-four);
            }
        }
    }
}

@mixin antdModalStyle {
    :global {
        .ant-modal-content {
            width: 100%;
            margin: 0;
            border-radius: 12px;
            background-color: var(--course-card-color) !important;
            border: solid 3px var(--highlight) !important;
            padding: 1.5rem !important;

            .ant-modal-body {
                border-radius: 12px;
                border-color: var(--course-card-color);
                color: var(--text-color);
            }

            .ant-modal-close {
                color: var(--text-color);

                &:hover {
                    color: var(--color-five);
                }
            }
        }
    }
}

@mixin antdMenuStyle {
    :global {
        .ant-menu {
            .ant-menu-item {
                &.ant-menu-item-active {
                    &::after {
                        border-bottom-color: transparent;
                    }
                }

                &.ant-menu-item-selected {
                    color: var(--menu-text-selected-color);
                    font-weight: $default-bold-weight;

                    &::after {
                        border-bottom-color: var(--menu-text-selected-color);
                    }
                }

                &:hover {
                    color: var(--menu-text-color) !important;

                    &::after {
                        border-bottom-color: var(--menu-text-selected-color);
                    }
                }
            }
        }
    }
}

@mixin antdCheckbox {
    @include delayed;

    &:hover {
        :global {
            .ant-checkbox {
                .ant-checkbox-inner {
                    border-color: var(--color-five);
                }
                &:after {
                    border-color: var(--color-five) !important;
                    border-width: 2px;
                }
            }
        }
    }

    :global {
        .ant-checkbox {
            @include delayed;

            &:after {
                border-color: transparent;
            }
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--white);
            border: solid 1px var(--color-five);
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: var(--color-one) !important;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: var(--color-one);
        }

        .ant-checkbox-disabled {
            .ant-checkbox-inner {
                background-color: var(--gray);

                &:after {
                    border-color: rgba(0, 0, 0, 0.25) !important;
                }
            }
        }
    }
}

@mixin antdFormStyle {
    :global {
        .ant-input-affix-wrapper {
            background-color: var(--highlight);
            border-color: var(--form-input-outline-color);
            color: var(--text-color);
            border-radius: $border-radius;

            .ant-input {
                background-color: var(--highlight);
                border-color: var(--form-input-outline-color);
                color: var(--text-color);

                &::placeholder {
                    color: var(--text-color);
                    opacity: 50%;
                }
            }

            .ant-input-suffix {
                .anticon {
                    color: var(--text-color);
                    opacity: 50%;
                }
            }
        }

        // https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
        input:-webkit-autofill,
        input:-webkit-autofill:focus {
            transition:
                background-color 600000s 0s,
                color 600000s 0s;
            -webkit-box-shadow: 0 0 0 1000px var(--highlight) inset;
            -webkit-text-fill-color: var(--text-color);
        }

        .ant-form-item-control-input-content {
            background-color: var(--highlight);
            border-color: var(--highlight);
            color: var(--text-color);
            border-radius: $border-radius;

            .ant-input {
                background-color: var(--highlight);
                border-color: var(--highlight);
                color: var(--text-color);

                &::placeholder {
                    color: var(--text-color);
                    opacity: 50%;
                }
            }

            .ant-input-suffix {
                .anticon {
                    color: var(--text-color);
                    opacity: 50%;
                }

                .anticon-check-circle {
                    color: var(--color-three);
                    opacity: 100%;
                }
            }
        }
    }
}

@mixin antdPagination {
    :global {
        .ant-pagination {
            .ant-pagination-item {
                a {
                    color: var(--text-color);
                }

                &.ant-pagination-item-active {
                    color: var(--color-five);
                    background-color: transparent;
                    border: solid 1px var(--white);
                }
            }

            .ant-pagination-item-link {
                color: var(--text-color);
            }
        }
    }
}

@mixin antdRadio {
    :global {
        .ant-radio-wrapper {
            .ant-radio-checked {
                .ant-radio-inner {
                    border-color: var(--color-one);
                    background-color: var(--color-one);
                }
            }
        }
    }
}

@mixin antdCollapse {
    :global {
        .ant-collapse {
            .ant-collapse-item {
                .ant-collapse-header {
                    // centers collapse arrow vertically
                    .ant-collapse-expand-icon {
                        height: unset;
                        align-self: stretch;
                        @include center-children-vertically;
                    }
                }
            }
        }
    }
}

@mixin antdSelectStyle {
    :global {
        .ant-select {
            .ant-select-selector {
                background-color: var(--course-card-color) !important;
                padding-top: 0;
                border: none;
                color: var(--color-five);
                font-weight: $default-bold-weight;
                max-width: $course-card-max-width;
                cursor: pointer !important;
                .ant-select-selection-placeholder {
                    color: var(--color-five);
                }

                @include delayed;
                &:hover {
                    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
                }

                .ant-select-selection-item {
                    @include delayed;
                    color: var(--color-five);
                    font-weight: $default-bold-weight;
                }
            }

            .ant-select-arrow {
                color: var(--color-five);
            }

            .ant-dropdown-menu-item {
                padding: 5px 0;
                color: var(--text-color) !important;
                width: $course-card-max-width;
                &:hover {
                    background-color: var(--black-ten-pct-lighter) !important;
                }
            }
        }
    }
}

@mixin antdSelectorDropdownStyle {
    background-color: var(--course-card-color) !important;
    color: var(--text-color) !important;
    :global {
        .ant-select-item,
        .ant-select-item-option-selected {
            background-color: var(--course-card-color) !important;
            color: var(--text-color) !important;
        }

        .ant-select-item {
            @include delayed;
            cursor: pointer;
            &:hover {
                background-color: var(--light-gray) !important;
            }
        }

        .ant-select-item-option-selected {
            color: var(--color-five) !important;
        }
    }
}

@mixin antdCalendar {
    :global {
        .ant-picker-calendar {
            .ant-picker-content {
                th {
                    color: var(--text-color);
                    font-weight: $default-bold-weight;
                    border-bottom: solid 1px var(--white);
                    margin-bottom: 1rem;
                }
            }

            .ant-picker-cell-in-view {
                .ant-picker-calendar-date {
                    border-top: solid 4px var(--color-five);
                }
            }

            .ant-picker-cell {
                color: var(--background);
            }

            .ant-picker-cell-selected {
                border: solid 2px var(--color-three);
                border-radius: $border-radius;

                .ant-picker-cell-inner {
                    background-color: var(--color-five) !important;

                    &::before {
                        border: none;
                    }
                }
            }

            .ant-picker-cell-in-view {
                color: var(--text-color);

                .ant-picker-calendar-date-value {
                    color: var(--black);
                }
            }

            .ant-picker-cell-disabled {
                .ant-picker-calendar-date-value {
                    color: var(--text-color);
                }

                .ant-picker-calendar-date {
                    border-top: none;
                }
            }
        }
    }
}

@mixin antdInput {
    :global {
        .ant-input {
            background-color: red;
        }
    }
}