@import './variables';

@mixin respond-to($breakpoint) {
    @if $breakpoint == xs {
        @media (max-width: 576px) {
            @content;
        }
    } @else if $breakpoint == sm {
        @media (max-width: $logged-in-mobile-mode-breakpoint) {
            @content;
        }
    } @else if $breakpoint == md {
        @media (max-width: $notecard-screen-small-max-width) {
            @content;
        }
    } @else if $breakpoint == lg {
        @media (max-width: $large-breakpoint) {
            @content;
        }
    } @else if $breakpoint == lg2 {
        @media (max-width: $small-desktop-mode-breakpoint) {
            @content;
        }
    } @else if $breakpoint == xl {
        @media (max-width: $ultra-large-screen-min-width) {
            @content;
        }
    }
}
