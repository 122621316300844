@import '../../../styles/variables';
@import '../../../styles/responsiveText';

.notificationListItemContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 0.75rem;
    padding-left: 0.75rem;
    height: 85px;
    border-radius: 0.5px;

    &:hover {
        background-color: var(--highlight);
        border-radius: $border-radius;
    }

    .notificationListItemAvatar {
        flex: 1 1 10%;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .notificationListItemTextContainer {
        line-height: $line-height;
        flex: 1 1 80%;
        flex-direction: column;
        justify-content: center;

        .notificationListItemTextInfo {
            color: var(--text-color);
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .notificationListItemTextTime {
            color: var(--color-five);
            font-size: 14px;
        }
    }

    .notificationListItemDot {
        flex: 1 1 10%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--color-one);

        &.hidden {
            visibility: hidden;
        }
    }
}

.avatar {
    color: white;
    cursor: default;
    border-radius: 50%;
    margin: 0 auto;
    @include responsive-text-small;

    &.avatarStudent {
        background: var(--color-two);
    }

    &.avatarInstructor {
        background: var(--color-one);
    }

    &.avatarFeature {
        background: var(--color-eight);
    }

    &.avatarAnnouncement {
        background: var(--color-four);
    }

    &.avatarNewCourseContent {
        background: var(--red);
    }
}