@import '../../../styles/responsiveText';
@import '../../../styles/variables';
@import '../../../styles/clickableText.module';
@import '../../../styles/delayed';
@import '../../../styles/centerChildrenVertically.module';

.personalSection {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    //padding-right: 2rem;

    &.mobileMode {
        padding-right: 0;

        button {
            margin: 0;
        }
    }

    .takeMeBack {
        margin-right: 1rem;
    }

    .notificationsBadge {
        @include center-children-vertically;
        margin-right: 2rem;

        :global {
            .anticon {
                @include delayed;

                &:hover {
                    color: var(--color-five) !important;
                    @include clickMe;
                }
            }

            .ant-badge {
                .ant-badge-count {
                    box-shadow: none;
                    font-weight: 800;
                }
            }
        }
    }

    .giftBadgeContainer {
        @include center-children-vertically;

        .giftBadge {
            margin-right: 1rem;
            @include delayed;

            &:hover {
                color: var(--color-five) !important;
                @include clickMe;
            }
        }
    }

    .welcomeBack {
        @include center-children-vertically;
        color: var(--text-color);

        .welcomeBackText {
            font-size: 12px;
        }

        .displayNameText {
            font-size: 18px;
            font-weight: 700;
        }
    }

    .initialIcon {
        background-color: var(--color-nine);
        color: var(--text-color) !important;
        font-size: 18px;
        border-radius: 8px;
        font-weight: $default-bold-weight;
        margin: 0.75rem 0 0.75rem 0.75rem;
        min-height: 45px;
        //min-width: 45px;
        text-align: center;
        width: fit-content;
        height: fit-content;
        border: none;
        //font-family: Manrope;

        @include center-children-vertically;

        &:hover {
            background-color: var(--color-eight);
            color: var(--text-color) !important;
        }

        &.dropdownOpen {
            background-color: var(--color-eight);
            color: var(--text-color);
        }
    }
}

.personalSectionDropdownMenu {
    :global {
        .ant-dropdown-menu {
            background-color: var(--color-nine) !important;

            .ant-dropdown-menu-item {
                padding: 5px 0;

                &:hover {
                    background-color: var(--color-eight) !important;
                }
            }
        }
    }
}
