@import '../../../styles/variables';
@import '../../../styles/media';
@import '../../../styles/antdStyles.module';
@import '../../../styles/limitMaxWidth.module';

.loggedOutHeaderOuterContainer {
    background: var(--black);

    .loggedOutHeaderContainer {
        @include limit-max-width;
        display: flex;

        .loggedOutHeaderMenu {
            @include antdMenuStyle;
            background: var(--black);
            color: var(--menu-text-color);
            display: flex;
            margin-left: auto;
            padding-right: 0.5rem;

            @include respond-to(xs) {
                padding-right: 0;
            }

            :global {
                .ant-menu-item {
                    padding-inline: 12px;
                    font-size: 18px;
                    @include respond-to(xs) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
