@import '../../../../styles/responsiveText';
@import '../../../../styles/clickableText.module';
@import '../../../../styles/delayed';
@import '../../../../styles/media';
@import '../../../../styles/hiddenScrollBars.module';

.searchResults {
    color: var(--text-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    // separate component when mobile mode (<800px)
    //@include respond-to(sm) {
    //    grid-template-rows: 1fr 1fr 1fr;
    //    grid-template-columns: none;
    //}
    width: 100%;
    height: 100%;
    grid-gap: 1rem;

    a {
        color: var(--text-color);
    }

    @include responsive-text-extra-small;

    .searchResultsColumn {
        @include hideScrollBars;
        height: 100%;
        display: flex;
        flex-direction: column;

        .searchResultsBodyContainer {
            @include hideScrollBars;
            height: 100%;
        }
    }
}
