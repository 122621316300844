
.googleLoginContainer {
    text-align: center;

    $line-height: 0.1rem;

    h2 {
        font-size: 100%;
        text-align: center;
        border-bottom: 0.0625rem solid var(--background);
        margin: 0.625rem 20% 1.25rem;
        line-height: $line-height;
    }

    h2 span {
        background: var(--text-color);
        padding: 0 0.625rem;
        line-height: $line-height;
    }

    .googleLoginButtonContainer {
        margin: 1rem auto;

        :global {
            .ant-btn {
                float: none;
                font-weight: 700;
            }
        }
    }

    :global {
        .btn-container {
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            .error-text {
                position: static;
            }
        }
    }
}

// TODO: Likely will have a problem here?
// fixes issue of uneven spacing in modal (since modal doesn't get all the juicy goodness of flex from our base page...)
.ant-modal-body {
    .authenticator {
        padding: 0 !important;

        .googleLoginButtonContainer {
            :global {
                .ant-btn {
                    margin-top: 1rem;
                }
            }
        }
    }
}
