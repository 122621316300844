// Special screen width breakpoints for media queries
$min-width: 360px;
$extra-small-screen-max-width: 500px;
$small-screen-max-width: 600px;
$medium-screen-max-width: 1000px;
$problem-set-review-small-screen-max-width: 900px;
$notecard-screen-small-max-width: 1000px;
$large-screen-min-width: 1200px;
$ultra-large-screen-min-width: 1500px;
$ultra-extra-large-screen-min-width: 2000px;

// Should be kept in sync with LOGGED_IN_MOBILE_MODE_BREAKPOINT_IN_PX in TopNavigation.tsx
$mobile-mode-breakpoint-logged-out: 800px;
$large-breakpoint: 1250px;
$logged-in-mobile-mode-breakpoint: 800px;
$logged-in-small-desktop-mode-breakpoint: 1400px;

$logged-in-wide-desktop-mode-nav-bar-width: 305px; // 201+64, possibly not used, but here just in case

// keep in sync with LOGGED_IN_SMALL_DESKTOP_MODE_NAV_BAR_WIDTH_IN_PX in src/components/ui/consts.ts
$logged-in-small-desktop-mode-nav-bar-width: 150px;
$logged-in-mobile-mode-nav-bar-width: 0;

// Should be kept in sync with LOGGED_IN_SMALL_DESKTOP_MODE_BREAKPOINT_IN_PX in TopNavigation.tsx
$small-desktop-mode-breakpoint: 1400px;

$font-size-base: 16px;
// Responsive text
$large-text-max-size: 64px;
$large-text-variable-size: 5vw;
$large-text-min-size: 32px;

$medium-text-max-size: 48px;
$medium-text-variable-size: 4vw;
$medium-text-min-size: 24px;

$medium-small-text-max-size: 36px;
$medium-small-text-variable-size: 3vw;
$medium-small-text-min-size: 18px;

$small-text-max-size: 24px;
$small-text-variable-size: 2vw;
$small-text-min-size: 14px;

$extra-small-text-max-size: 18px;
$extra-small-text-variable-size: 1.5vw;
$extra-small-text-min-size: 12px;

$extra-extra-small-text-max-size: 14px;
$extra-extra-small-text-variable-size: 1vw;
$extra-extra-small-text-min-size: 8px;

$antd-outline-btn-hover: #5694ba;
$smaller-bold-weight: 600;
$default-bold-weight: 700;
$faint-highlight-background: rgba(0, 0, 0, 0.06);

$hr-color: #9a9a9a;

$line-height: 1.5715;

$default-max-content-width: 1200px;
$logged-in-header-height: 4rem;
$footer-height: 5rem;

$border-radius: 8px;
$minimum-gutter-size: 1rem;

$course-card-max-width: 425px;
