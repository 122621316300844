$transition-duration: 0.3s;

@mixin delayed {
    transition: all $transition-duration !important;
}

@mixin delayedHoverUnderline {
    @include delayed;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: currentColor;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform $transition-duration;
    }

    &:hover::before {
        transform: scaleX(1);
        transition-delay: 0s;
    }
}
