@import '../../../styles/limitMaxWidth.module';
@import '../../../styles/responsiveText';
@import '../../../styles/variables';
@import '../../../styles/centerChildrenVertically.module';

.loggedInHeader {
    background-color: var(--background);
    display: flex;
    height: $logged-in-header-height;
    color: var(--text-color);
    justify-content: space-between;
    column-gap: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .personalBox {
        @include center-children-vertically;
    }

    .searchContainer {
        @include center-children-vertically;
        width: 100%;
    }
}
