@import '../../../styles/variables';
@import '../../../styles/responsiveText';
@import '../../../styles/media';
@import '../../../styles/clickableText.module';
@import '../../../styles/limitMaxWidth.module';
@import '../../../styles/hiddenScrollBars.module';

.searchModalContainer {
    color: var(--text-color) !important;
    @include limit-max-width;
    width: calc(100% - 6rem) !important;
    @include respond-to(sm) {
        width: calc(100% - 2rem) !important;
    }

    :global {
        .ant-modal-content {
            background-color: var(--background) !important;
            width: 100%;
            height: 80vh;
            margin: 0;

            .ant-modal-body {
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .ant-modal-close {
                color: var(--text-color) !important;

                &:hover {
                    color: var(--color-eleven) !important;
                }
            }
        }
    }

    .titleContainer {
        background-color: var(--black);
        padding: 10px 20px;
        border-radius: 8px;
        font-weight: $default-bold-weight;
        @include responsive-text-small;
        margin-bottom: 1.25rem;
    }

    .middleContainerContents {
        height: 100%;
        overflow: scroll;
        padding-bottom: 1rem;

        .middleContainerLoadingContents {
            display: flex;
            justify-content: center;
        }

        .middleContainerImgContainer {
            display: flex;
            justify-content: center;

            img {
                margin: 4rem auto 0;
            }
        }
    }

    .userInputContainer {
        background-color: var(--black);
        padding: 1rem;
        border-radius: 8px;

        @include responsive-text-extra-small;

        .questionContainer {
            background-color: var(--background);
            height: 4rem;
            border-radius: 8px;
            padding: 0.5rem;
            display: flex;
            justify-content: space-between;

            :global {
                .ant-input-affix-wrapper {
                    background-color: var(--background);
                    border-color: var(--background);
                    box-shadow: none !important;

                    .ant-input-clear-icon {
                        color: var(--text-color);

                        &:hover {
                            color: var(--white-twenty-five-pct-darker);
                        }
                    }
                }
            }

            input {
                width: 100%;
                background-color: var(--background);
                border-color: var(--background);
                color: var(--text-color);
                box-shadow: none;
            }

            :global {
                .ant-input {
                    background-color: var(--background);
                    border-color: var(--background);
                    color: var(--text-color);
                    @include responsive-text-extra-small;

                    &::placeholder {
                        color: var(--text-color) !important;
                        opacity: 75%;
                    }

                    &:focus {
                        background-color: var(--background);
                        border-color: var(--background) !important;
                        box-shadow: none;
                        color: var(--text-color);
                    }
                }
            }

            .sendButtonContainer {
                background-color: var(--color-one);
                height: fit-content;
                padding: 0.5rem;
                border-radius: 8px;

                &.disabled {
                    user-select: none;
                    background-color: var(--background);

                    &:hover {
                        cursor: not-allowed;
                        background-color: var(--background);
                    }
                }

                &:hover {
                    cursor: pointer;
                    background-color: var(--color-five);
                }
            }
        }

        .instructionContainer {
            margin-top: 1rem;
        }
    }
}
