@import '../../styles/variables';
@import '../../styles/responsiveText';
@import '../../styles/antdStyles.module';
@import '../../styles/hiddenScrollBars.module';

.notificationModal {
    @include antdModalStyle;
    :global {
        .ant-modal-content {
            border-radius: 10px;
            max-height: 500px;
            @include hideScrollBars;
            padding: 1rem;
        }

        .ant-list-item {
            border-block-end: 1px solid var(--background) !important;

            &:hover {
                background-color: var(--color-five);
            }

            .ant-list-item-meta {
                .ant-list-item-meta-avatar {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 50px;
                }
            }
        }
    }

    .notificationModalTitle {
        @include responsive-text-medium-small;
        padding-bottom: 0.5rem;
        font-weight: $default-bold-weight;
        display: flex;
        justify-content: center;
    }

    .notificationModalShowMoreButtonContainer {
        padding-top: 1vw;
        display: flex;
        justify-content: center;
    }
}
