@import '../../../styles/limitMaxWidth.module';
@import '../../../styles/responsiveText';
@import '../../../styles/variables';
@import '../../../styles/centerChildrenVertically.module';

.loggedInHeader {
    .topRow {
        background-color: var(--background);
        display: flex;
        height: $logged-in-header-height;
        @include limit-max-width;
        color: var(--text-color);
        justify-content: space-between;
        margin: 0 auto;

        .personalBox {
            margin-right: 1.5rem;
        }

        .searchContainer {
            @include center-children-vertically;
            width: 100%;
        }

        .personalSectionContainer {
            @include center-children-vertically;
        }
    }
}
