@import '../../../../styles/variables';
@import '../../../../styles/centerChildrenVertically.module';

.personalSectionDropdownMenuItemContainer {
    .personalSectionDropdownMenuItem {
        background: inherit;
        color: var(--text-color);
        border: none;
        display: flex;
        width: 100%;
        justify-content: right;
        column-gap: 0.5rem;
        box-shadow: none;

        .iconContainer {
            @include center-children-vertically;
        }

        img {
            width: 30px;
            height: auto;
        }

        :global {
            .ant-btn-default {
                &:hover {
                    color: var(--text-color);
                }
            }
        }

        &:hover {
            color: var(--text-color) !important;
        }
    }
}
