@import '../../../../styles/variables';
@import '../../../../styles/antdStyles.module';

.submitFeedbackButton {
    font-weight: $default-bold-weight;
}

.feedbackModalContainer {
    @include antdModalStyle;
    :global {
        .ant-modal-content {
            padding: 3rem 1rem;
        }
    }

    .feedbackModal {
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
        color: var(--text-color);
        .feedbackHeader {
            background-color: var(--black);
            border-radius: $border-radius;
            padding: 0.5rem;

            .feedbackModalTitle {
                font-size: 150%;
                font-weight: $default-bold-weight;
                display: flex;
                justify-content: center;
            }

            .feedbackModalSubtitle {
                padding-top: 0.5rem;
                text-align: center;
            }
        }

        .feedbackModalBody {
            margin-top: 2rem;

            .feedbackModalRadioContainer {
                .feedbackModalRadioContainerQuestion {
                    margin-bottom: 1rem;
                }

                :global {
                    .ant-radio-group {
                        .ant-radio-button-wrapper {
                            background-color: var(--black);
                            color: var(--text-color);

                            &.ant-radio-button-wrapper-checked {
                                border: solid 1px var(--color-one);
                                background-color: var(--highlight);
                            }
                        }
                    }
                }
            }

            .feedbackModalFreeResponseContainer {
                margin-top: 1rem;
                :global {
                    .ant-input-data-count {
                        color: var(--text-color);
                    }
                }
                .feedbackModalFreeResponsePrompt {
                }
            }

            .feedbackModalUploadContainer {
                margin-top: 1rem;

                :global {
                    .error-text {
                        margin-top: 0.5rem;
                        position: static;
                    }

                    .ant-upload-wrapper {
                        color: var(--text-color);

                        .ant-upload-list-item-actions {
                            .anticon {
                                color: var(--color-five);

                                &:hover {
                                    color: var(--color-three);
                                }
                            }
                        }
                    }
                }
            }

            .feedbackModalSubmitButtonContainer {
                margin-top: 1rem;
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }
}
