@import '../../../../styles/hiddenScrollBars.module';

.menuContainer {
}

.activeContentTypeContainer {
    height: 100%;

    .searchResultsBodyContainer {
        @include hideScrollBars;
        height: 100%;
    }
}
